import chiomaAkpuruLogo from "../assets/chioma-akpuru-logo.png";
import chiomaAkpuruLogoWhite from "../assets/chioma-akpuru-logo-white.png";
import twjLogo from "../assets/twj-logo.png";
import twjLogoWhite from "../assets/twj-logo-white.png";
import nelzLogo from "../assets/nelz-logo.png";
import nelzLogoWhite from "../assets/nelz-logo-white.png";
import michaelsLogo from "../assets/michaels-logo.png";
import michaelsLogoWhite from "../assets/michaels-logo-white.png";
import earnablerLogo from "../assets/earnabler-logo.png";
import earnablerLogoWhite from "../assets/earnabler-logo-white.png";
import summervilleLogo from "../assets/summerville-logo.png";
import summervilleLogoWhite from "../assets/summerville-logo-white.png";
import dikinsLogo from "../assets/dikins-logo.webp";
import dikinsLogoWhite from "../assets/dikins-logo-white.webp";
import bmoLogo from "../assets/bmo-logo.webp";
import bmoLogoWhite from "../assets/bmo-logo-white.webp";
import apexLogo from "../assets/apex-logo.png";
import apexLogoWhite from "../assets/apex-logo-white.png";
import dansoLogo from "../assets/danso-logo.png";
import dansoLogoWhite from "../assets/danso-logo-white.png";
import kleanaLogo from "../assets/kleana.png";
import kleanaLogoWhite from "../assets/kleana-white.png";

const Clients = () => {
  return (
    <section className="dark-bg-2">
      {/* container start */}
      <div className="container small top-bottom-padding-120">
        {/* medium-title start */}
        <h2 data-animation-container className="medium-title">
          <span
            data-animation-child
            className="title-fill"
            data-animation="title-fill-anim"
            data-text="Fine Folks"
          >
            Fine Folks
          </span>
          <br />
          <span
            data-animation-child
            className="title-fill tr-delay01"
            data-animation="title-fill-anim"
            data-text="We’ve Worked"
          >
            We’ve Worked
          </span>
          <br />
          <span
            data-animation-child
            className="title-fill tr-delay02"
            data-animation="title-fill-anim"
            data-text="With"
          >
            With
          </span>
        </h2>
        {/* medium-title end */}
        {/* client-list start */}
        <ul className="client-list d-flex-wrap top-padding-60">
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={twjLogoWhite}
                  alt="TWJ Logo"
                  className="hover-opac-img"
                />
                <img src={twjLogo} alt="TWJ Logo" className="opac-img" />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={earnablerLogoWhite}
                  alt="EARNabler Logo"
                  className="hover-opac-img"
                />
                <img
                  src={earnablerLogo}
                  alt="EARNabler Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={chiomaAkpuruLogoWhite}
                  alt="Chioma Akpuru Logo"
                  className="hover-opac-img"
                />
                <img
                  src={chiomaAkpuruLogo}
                  alt="Chioma Akpuru Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={nelzLogoWhite}
                  alt="Nelz Management Logo"
                  className="hover-opac-img"
                />
                <img
                  src={nelzLogo}
                  alt="Nelz Management Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={michaelsLogoWhite}
                  alt="Michaels & Associates Logo"
                  className="hover-opac-img"
                />
                <img
                  src={michaelsLogo}
                  alt="Michaels & Associates Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={summervilleLogoWhite}
                  alt="Summerville Properties Logo"
                  className="hover-opac-img"
                />
                <img
                  src={summervilleLogo}
                  alt="Summerville Properties Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={dikinsLogoWhite}
                  alt="Dikins Multiventures Logo"
                  className="hover-opac-img"
                />
                <img
                  src={dikinsLogo}
                  alt="Dikins Multiventures Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={bmoLogoWhite}
                  alt="BMO Foundation Logo"
                  className="hover-opac-img"
                />
                <img
                  src={bmoLogo}
                  alt="BMO Foundation Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={apexLogoWhite}
                  alt="Apex Logo"
                  className="hover-opac-img"
                />
                <img src={apexLogo} alt="Apex Logo" className="opac-img" />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={dansoLogoWhite}
                  alt="Danso Group Logo"
                  className="hover-opac-img"
                />
                <img
                  src={dansoLogo}
                  alt="Danso Group Logo"
                  className="opac-img"
                />
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="pointer-large d-block">
              <div
                className="brand-box"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={kleanaLogoWhite}
                  alt="Kleana Logo"
                  className="hover-opac-img"
                />
                <img src={kleanaLogo} alt="Kleana Logo" className="opac-img" />
              </div>
            </a>
          </li>
          <li className="empty-spot-box">
            <a
              data-animation-container
              href="#/"
              className="pointer-large p-style-bold-up empty-spot d-block"
            >
              <span
                data-animation-child
                className="title-fill"
                data-animation="title-fill-anim"
                data-text="This spot"
              >
                This spot
              </span>
              <span
                data-animation-child
                className="title-fill tr-delay01"
                data-animation="title-fill-anim"
                data-text="Awaits"
              >
                Awaits
              </span>
              <span
                data-animation-child
                className="title-fill tr-delay02"
                data-animation="title-fill-anim"
                data-text="You"
              >
                You
              </span>
            </a>
          </li>
        </ul>
        {/* client-list end */}
      </div>
      {/* container end */}
    </section>
  );
};

export default Clients;
