import Footer from "../components/Footer";
import Header from "../components/Header";
import Typewriter from "typewriter-effect";
import Clients from "../components/Clients";
// import RecentWorks from "../components/RecentWorks";
// import Testimonials from "../components/Testimonials";
import Video from "../components/Video";

const Home = () => {
  return (
    <>
      <Header activePage="Home" />
      <main
        className="animsition-overlay"
        data-animsition-overlay="true"
        style={{ opacity: 1 }}
      >
        <section
          id="up"
          className="page-head flex-min-height-box dark-bg-2 typewriter-home _mPS2id-t mPS2id-target mPS2id-target-first mPS2id-target-last"
        >
          <div className="page-head-bg home-bg" />
          <div className="flex-min-height-inner">
            <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">
                Welcome to arvotek
              </h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">
                  We offer
                </span>
                <br />
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay02">
                  creative
                </span>
                <br />
                <Typewriter
                  options={{
                    strings: [
                      "& innovative ideas",
                      "digital solutions",
                      "business ideas",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">
                  Creative team
                </span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">
                  Innovative ideas
                </span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">
                  Best services
                </span>
              </p>
            </div>
          </div>
          <a href="#down" className="scroll-btn pointer-large _mPS2id-h">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">
                Scroll
              </span>
            </div>
          </a>
        </section>
        <section
          id="down"
          className="dark-bg-1 flex-min-height-box _mPS2id-t mPS2id-target mPS2id-target-first mPS2id-target-last"
        >
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">
              {/* flex-container start */}
              <div data-animation-container className="flex-container animated">
                {/* column start */}
                <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span
                      data-animation-child
                      className="title-fill animated title-fill-anim"
                      data-animation="title-fill-anim"
                      data-text="We are Creative"
                    >
                      We are Creative
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="title-fill tr-delay01 animated title-fill-anim"
                      data-animation="title-fill-anim"
                      data-text="We are Arvotek"
                    >
                      We are Arvotek
                    </span>
                  </h2>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="six-columns">
                  <div className="content-right-margin-20">
                    <p
                      data-animation-child
                      className="p-style-medium text-color-5 fade-anim-box tr-delay02 animated fade-anim"
                      data-animation="fade-anim"
                    >
                      We are Arvotek, a reliable service provider that ensures
                      innovation meets expertise! We are a passionate team of
                      digital enthusiasts dedicated to bringing your ideas to
                      life and propelling businesses to new heights. Our mission
                      is to partner with you on your digital journey, delivering
                      creative solutions that drive growth, engagement and
                      success.
                    </p>
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="six-columns">
                  <div className="content-left-margin-20">
                    <p
                      data-animation-child
                      className="p-style-medium text-color-5 fade-anim-box tr-delay03 animated fade-anim"
                      data-animation="fade-anim"
                    >
                      At Arvotek, we envision a world where every business,
                      regardless of size or industry, can harness the power of
                      digital technologies to thrive and excel. Our vision is to
                      be the bridge that connects your aspirations with the
                      digital realm, empowering you to achieve your goals and
                      dreams.
                    </p>
                  </div>
                </div>
              </div>
              {/* flex-container end */}
            </div>
            {/* container end */}
          </div>
          {/* flex-min-height-inner end */}
        </section>

        <section
          className="light-bg-1 bottom-padding-30 top-padding-120"
          data-midnight="black"
        >
          {/* container start */}
          <div
            data-animation-container
            className="container small bottom-padding-60 text-center animated"
          >
            <h2
              data-animation-child
              className="large-title text-height-10 text-color-1 overlay-anim-box2 animated overlay-anim2"
              data-animation="overlay-anim2"
            >
              Recent Works
            </h2>
            <br />
            <p
              data-animation-child
              className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5 animated fade-anim"
              data-animation="fade-anim"
            >
              We Offer Digital Solutions
            </p>
          </div>
          {/* container end */}
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-right-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Branding & Software development
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Earnabler by Zhangiev Enterprise
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        We developed the complete branding and visual identity
                        for this innovative financial investment app, creating a
                        design that conveys trust and professionalism. Our team
                        also designed and built a modern, user-friendly waitlist
                        website to engage potential users and drive anticipation
                        ahead of the app’s launch.
                      </p>
                      {/* <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div> */}
                    </div>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="#/"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/earnabler.png)",
                        }}
                      ></div>
                    </a>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </div>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="#/"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/lojay.jpg)",
                        }}
                      ></div>
                    </a>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-left-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Branding
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Lojay Automobiles
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        We crafted a distinctive and professional brand identity
                        for this car dealership, designed to reflect reliability
                        and customer trust. From the logo to the color palette
                        and typography, every detail was tailored to enhance the
                        dealership's presence and resonate with its target
                        audience.
                      </p>
                      {/* <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div> */}
                    </div>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </div>

          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-right-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Website Development
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Dikins Multiventures
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        We reimagined the website for Dikins Multiventures, a
                        pioneering company in the food manufacturing sector.
                        Established in 2005, the company is dedicated to
                        delivering high-quality products like banana bread,
                        coconut, and its derivatives to health-conscious
                        consumers worldwide. The redesign focused on creating a
                        modern, user-friendly platform that highlights their
                        mission, product range, and expansive distribution
                        network across Nigeria and beyond. The updated design
                        aligns with their goal of inspiring global consumers to
                        embrace healthy living.
                      </p>
                      {/* <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div> */}
                    </div>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="#/"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/dikins.jpeg)",
                        }}
                      ></div>
                    </a>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </div>
        </section>

        {/* <RecentWorks /> */}
        <Clients />
        {/* <Testimonials /> */}
        <Video />
      </main>
      <Footer activePage="Home" />
    </>
  );
};

export default Home;
